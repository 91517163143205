import { PLATFORM } from 'aurelia-pal';
import { computedFrom } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Tasks } from 'services/tasks';
import { c } from 'common/common';

export class List {
    static inject = [EventAggregator, Tasks];
    _ea;
    _tasks;

    tasks;
    currentIndex = 0;
    _handlers = [];

    constructor(ea, tasks) {
        this._ea = ea;
        this._tasks = tasks;
    }

    activate(model) {
        this.taskId = model && model.id ? model.id : null;
    }

    attached() {
        this._handlers.push(this._ea.subscribe(c.EventKeys.tasks.updated, () => this._load()));
        this._load();
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    _load() {
        try {
            this.working = true;
            this.tasks = this._tasks.current;
            if (this.tasks.length === 0) {
                this.currentIndex = undefined;
                this.task = undefined;
                return;
            }
            if (this.currentIndex === undefined) this.currentIndex = 0;
            if (this.currentIndex >= this.tasks.length) this.currentIndex = 0;
            this.task = this.tasks[this.currentIndex];
        } catch (err) {
            this.tasks = [];
        } finally {
            this.working = false;
        }
    }

    _resetTaskIndex() {
        if (this.tasks.length === 0) {
            this.currentIndex = undefined;
            this.task = undefined;
        } else {
            this.currentIndex = 0;
            this.task = this.tasks[this.currentIndex];
        }
    }

    @computedFrom('tasks', 'currentIndex')
    get canGoNext() {
        if (!this.tasks) return false;
        return this.tasks.length - 1 > this.currentIndex;
    }

    @computedFrom('tasks', 'currentIndex')
    get canGoBack() {
        if (!this.tasks || this.tasks.length === 0) return false;
        return this.currentIndex > 0;
    }

    next() {
        this.currentIndex++;
        this.task = this.tasks[this.currentIndex];
    }

    previous() {
        this.currentIndex--;
        this.task = this.tasks[this.currentIndex];
    }

    openProfile(id) {
		this._ea.publish(c.EventKeys.site.openProfile, { member: null, memberId: id });
    }

    taskViewModel(task) {
        switch (task.taskType) {
            case 'annuity-issued-paid-30d': return PLATFORM.moduleName('./task-type/annuity-issued-paid-30d');
            case 'data-feed-exception': return PLATFORM.moduleName('./task-type/data-feed-exception');
            case 'LevelChangeRequest': return PLATFORM.moduleName('./task-type/LevelChangeRequest');
            case 'NewAgentLevelRequest': return PLATFORM.moduleName('./task-type/NewAgentLevelRequest');
            case 'policy-charged-back': return PLATFORM.moduleName('./task-type/policy-charged-back');
            case 'reauth-nylas': return PLATFORM.moduleName('./task-type/reauth-nylas');
            case 'review-translation': return PLATFORM.moduleName('./task-type/review-translation');
            case 'set-surance-bay-affiliation': return PLATFORM.moduleName('./task-type/set-surance-bay-affiliation');
            case 'upline-contracting-reminder-ignored-process': return PLATFORM.moduleName('./task-type/upline-contracting-reminder-ignored-process');
            case 'upline-contracting-reminder': return PLATFORM.moduleName('./task-type/upline-contracting-reminder');
            case 'emergency-contact-before-policy': return PLATFORM.moduleName('./task-type/emergency-contact-before-policy');
        }
        return null;
    }

    taskCompleted(index) {
        try {
            this.tasks.splice(index, 1);
        } catch (err) {
            console.log(err);
        }
    }
}
